<template>
    <div class="ServiceAgreement">
        <!-- <h1>服务协议</h1> -->
        <img src="https://img.youstarplanet.com/h5/ServiceAgreement.png" alt="">
        <ul class="content">
            <li>服务协议</li>
            <li>
                最近修订日期：2021年12月1日
            </li>
            <li>
                <br><span>介绍</span><br>
                欢迎您使用星都时代的服务！我们能够为用户提供多样化的服务。您使用我们的产品和服务（「服务」或「我们的服务」）须受本《服务条款》（「本条款」）所限制，请详细阅读。<br>
                多谢您详细审阅本条款，希望您对我们的服务感到满意。<br>
                <br><span>遵守本条款</span><br>
                如您是全球任何地方使用我们服务的用户，则此等条款适用于您，除非您是以下任何类别的使用者：(i)在中华人民共和国使用我们服务的用户（不论任何国籍）；(ii)在全球任何地方使用我们服务的中华人民共和国公民；或(iii)在全球任何地方使用我们服务的中国注册公司。倘若您是任何上述类别的用户，您使用我们服务时适用 《服务条款（中国用户）》的条款。<br>
                请参閲本条款及我们的政策和指引，藉以明瞭您可如何使用我们的服务及在什么情况下不可使用我们的服务。您使用我们的服务时，不论身在何处，必须遵守本条款，并只可按照适用法律和规例所允许而使用我们的服务。<br>
                如您不同意本条款，则必须停止使用我们的服务。<br>
                <br><span>订约实体</span><br>
                您使用我们的服务，即表示同意与深圳市星都时代文化科技有限公司（Shen Zhen You-Star-Planet International Art-Tech Service Co.,Ltd）及旗下分、子公司（「我们」）签订本条款。<br>
                我们可在某些特定服务条款中规定，您使用我们某项或多项服务（相关特定服务条款适用）时，是与我们某一家关联公司（而非深圳市星都时代文化科技有限公司（Shen Zhen You-Star-Planet International Art-Tech Service Co.,Ltd））订约。在上述情况下，相关特定服务条款会明确相关的订约实体，而本条款的所有条款及细则（除上一段外）藉提述而被纳入该等相关的特定服务条款。<br>
                <br><span>有关本条款的其他条款</span><br>
                如您未满13岁，则不得使用我们的服务。如您介乎13至 18岁（或在您的司法管辖区会被视为未成年人的有关年龄），您的家长或监护人（以其本人和您的代表身份）必须同意本条款，您才可使用我们的服务。<br>
                如您代表一家公司、合伙公司、组织、政府或其他机构（您的「机构」）使用我们的服务，您须保证已获授权如此行事，并获授权促使您的机构受本条款所约束。在上述情况下，「您」将包括您的机构。<br>
                我们可能将本条款翻译成多种语言。如本条款的英文版与任何其他语言的版本有任何差异之处，将以英文版为准（在存在差异的范围内及在适用法律和规例允许的范围内）。<br>
                <br><span>额外条款和政策</span><br>
                我们提供多样化的服务，因此您使用我们所有服务或特定服务时，本部分或者不时向您告知的所载的若干额外条款和政策可能适用。本额外服务条款和政策全部构成本条款的一部分，并纳入本条款之内。<br>
                <br><span>特定服务的额外条款</span><br>
                我们有专为使用某些服务而设的额外条款（如我们向您发出的通知所述），您使用这些服务时必须遵守这些额外条款：<br>
                星动演艺条款 – 载列对您使用星动演艺适用的条款（包括星动演艺内的各种服务（如星动演艺平台服务），在该星动演艺条款内明确説明）；<br>
                最终用户特许协议 - 载列对您使用我们的软件（包括我们的移动应用、桌面软件和工具系统）适用的条款；及特定对我们通过我们的服务提供的任何特别推广活动（例如抽奖、比赛和调查）适用的条款。<br>
                <br><span>额外政策</span><br>
                下文载有您使用我们的服务时必须遵守的特定政策：<br>
                《私隐政策》 － 载有我们如何收集、储存和使用您的个人资料；<br>
                《Cookies政策》 － 载有我们如何在我们的服务中使用cookies和记录档。<br>
                《允许使用政策》 － 载有您使用我们的服务时适用的良好行为规则；<br>
                <br><span>特定国家的额外条款</span><br>
                如您是美国及澳洲国家的公民或常住居民，您使用我们的服务时亦适用以下特定国家条款。在任何额外条款或政策与本条款有差异的范围内，有关额外条款或额外政策将适用，但该等额外条款或额外政策（除非在该等额外条款或额外政策另有明确订明则例外）不会修改本条款的以下部分：<br>
                「修改条款与我们的服务」<br>
                「我们的知识产权」<br>
                「对我们的服务负法律责任」<br>
                然而，在任何特定国家条款与本条款、或任何额外条款或政策有差异的范围内，不论本条款任何部分牵涉其中，有关特定国家条款均应适用。<br>
                <br><span>修改条款与我们的服务</span><br>
                我们可能不时修改本条款，故请不时返回本条款翻阅查询。<br>
                此外，由于我们的服务和用户经验不断逐步发展，我们可能不时（并在适用法律和规例允许的范围内）增加、变更或删除我们的服务项目（包括是否免费提供某服务），或暂停或完全终止某服务。<br>
                我们若认为本条款或我们的服务的任何修改是合理重要的修改，我们将在修改生效前通过我们的网站、与您直接通讯或以其他方式通知您。<br>
                在我们修改本条款或我们的服务后，不论我们有没有发出通知，而您仍继续使用我们的服务，即表示同意被经修订的本条款所约束。<br>
                <br><span>您的账户</span><br>
                您可能需要向我们开立账户以进入并使用我们某些服务，如特定服务账户或软件服务号码。<br>
                您使用您的软件服务号码除了受本条款所限制外，亦受《软件服务-号码政策》所限制。<br>
                您的账户乃是您的个人账户，您不得馈赠、借出或转让任何其他人，或以其他方式允许任何其他人进入或使用您的账户。您在我们的服务范围内採用的账户名称、用户识别号和其他识别资料，均为我们的财产，在您的账户不论何故被您或我们终止或撤销后，我们可以取消、取回和重用该等资料。<br>
                您须负责： (i) 保管用以进入您的账户和我们的服务的任何密码；(ii) 承担通过您的账户对我们的服务所作出的一切使用行为。如您发现或怀疑您的账户或密码已被洩露，必须尽快（如为软件服务号码，请按《软件服务 号码政策》所示的联络方式）通知我们。除非我们已接获并确认收到您向我们发出关于您的账户/密码被洩露的通知，我们会视所有在我们的服务内对您的账户的使用均是由您作出。<br>
                <br><span>撤销账户</span><br>
                您亦可撤销您的特定服务账户－ 有关其他指引，请参阅相关的特定服务条款。<br>
                <br><span>付款</span><br>
                您可以不时在我们的服务上支付服务费（无论是支付给我们或者第三方），作为您使用我们服务的一部分（包括我们提供的服务，或在我们的服务范围内提供的某些额外服务项目）。我们的特定服务条款载有该等付款适用的其他条款（包括有关退款（如有）、账单安排和欠缴服务费的任何后果）。您必须遵守所有有关向我们付款的相关条款。<br>
                <br><span>您的内容</span><br>
                当您使用我们的服务而提交、上传、传送或显示任何数据、资料、媒体或其他内容（「您的内容」）时，您知道并同意：<br>
                您将继续拥有您的内容，并对您的内容负责；<br>
                我们不会将您的内容卖给任何第三方；<br>
                我们收集、处理及使用您的内容须受我们的《私隐政策》所限制；<br>
                您授权我们和我们的关联公司使用您的内容（我们无须向您支付费用或收费），包括以您的内容製作衍生作品、公开展示及公开表演您的内容，作为提供、宣传、发展和尝试提升我们的服务之用，包括您提交您的内容所使用的相关服务以及我们现时或日后可能提供的任何其他服务；<br>
                我们可使用您就您的内容所提供的名称（不论是否您的账户名称、真实名称或其他名称）；<br>
                在使用您的内容作该等用途时，只要您的内容已纳入您所用由我们提供的服务内，我们和我们的关联公司可以在全球各地于任何媒体，并通过任何发布方法，包括日后发展的媒体和方法，复印、复制、处理、储存、处理、改写、修改、翻译、履行、发布和刊登您的内容；<br>
                我们可以与协助我们提供、宣传、发展和提升我们的服务的第三方分享您的内容，但我们不会将您的内容售予该等第三方（我们的关联公司除外），作其本身用途（即与我们的服务无关的用途）；<br>
                在提交您的内容时，您需遵守本条款，包括我们的《允许使用政策》。<br>
                此外，您同意我们和我们的关联公司（须受本条款、我们的《私隐政策》和适用法律和规例所限制）：<br>
                可以在您停止使用我们的服务后，保留并继续使用您的内容 － 例如，您已与我们的服务的其他用户分享您的内容；<br>
                可能需要保留或披露您的内容，以便遵守适用法律和规例或遵从法院命令、传召或其他法律程序，我们和我们的关联公司也可以因应政府当局、执法机构或类似组织（不论是否设于您的司法管辖区或其他地区）的合法要求而披露您的内容；<br>
                我们可能为了强制执行本条款、保护我们的权利、财产或安全或我们关联公司、我们的服务的其他用户的权利、财产或安全而需要披露您的内容（包括与您在我们开立的账户有关的任何资料）。<br>
                您理解即使您要求从我们的服务删除您的内容，但基于技术和行政问题，我们可能需要若干时间才可完成，或甚可能无法完成，例如，我们可能无法阻止任何第三方储存或使用您已经通过我们的服务公开的您的内容。<br>
                我们保留权利可就任何理由（包括在我们认为适当时或适用法律和规例所规定）阻截或删除您的内容。<br>
                <br><span>对您的内容负责</span><br>
                您须对您的内容负上全责，我们建议您在任何时间均保存该内容的后备档案。您必须在任何时间确保：(i) 您具备所需权利提交、传送或显示您的内容，并授予我们本条款所载的权利；及 (ii) 您的内容（和我们根据本条款使用您的内容）没有侵犯或违反任何人的权利或触犯任何其他适用法律或规例。<br>
                <br><span>侵权</span><br>
                我们会对声称的侵权（包括对知识产权的侵犯、诽谤和对于其他民事权利的侵犯）的通知或者其他诉求和要求作出反应。<br>
               <br><span>第三方内容和服务</span><br>
                我们不会对提交给我们的服务的任何内容，或由我们的服务传送、显示或连结的任何内容（包括任何您的内容或我们的服务的其他用户或我们的广告商所提供的其他内容）的合法性、准确性或可靠性负责，也不会就此给予认可、支持或保证。您确认知晓并同意，当您使用我们的服务时，您有可能会遇上不准确、误导、诽谤性、令人反感或非法的内容。凡因您依赖或使用任何从我们的服务获得或取得的内容而产生的风险，一概由您自行承担。对于您因使用我们的服务而获得或取得的内容，您使用我们的服务并不赋予您任何于该等内容或对该等内容的权利。<br>
                我们亦不保证任何通过我们的服务授予、提供、宣传或连结的第三方服务的质素、可靠性或适当性，我们也不会就您该等第三方服务的使用或您与该等第三方服务的关係负责。如您通过我们的服务进入第三方服务，您必须遵守该等服务适用的任何条款和条件。<br>
                我们可能审阅（但不承诺一定会审阅）通过我们的服务提供的内容和第三方服务，从而决定其能否遵守我们的政策、适用法律和规例或是否会引起其他异议。如某些内容或第三方服务侵犯知识产权，或是不雅、诽谤性或粗秽的，或违反任何权利或对我们的服务安全或执行构成风险，我们可以删除或拒绝提供或连结到某些内容或第三方服务。<br>
                在我们服务出现的第三方内容和服务可能不时受其他条款的约束，包括原先制作该等内容和服务的有关第三方（例如负责撰写在我们服务所出现的报道的新闻机构或拥有您在我们的服务上取用的相关歌曲的版权的唱片公司）的条款。在该情况下，您同意就该第三方内容和服务遵守获通知的更多条款和细则。<br>
                <br><span>我们服务的广告内容</span><br>
                我们有些服务可能包含广告或商业内容。您同意我们可以在我们的服务内加入、展示及在其他方面传达广告或商业内容，并（在合理可行的情况下）辨别已支付的服务和通讯。您亦同意，正如我们的《私隐政策》已更详细解释，我们会使用针对目标对象的广告，尝试提供更适合您并对您有用的广告。<br>
                <br><span>我们的知识产权</span><br>
                所有关于或有关我们的服务和软件（包括任何日后的更新、升级和新版本）的知识产权将继续归我们和我们的特许持有人所有。除非本条款另行明确规定，否则您无权使用我们的知识产权。特别是，未经我们事先书面同意，您无权使用我们的商标或产品名称（例如星都时代或软件服务）、标识、域名或其他独特品版特徵。您向我们提供任何有关我们服务的意见或建议全部均是自愿的，我们可以酌情决定随意使用该等意见和建议，而不须向您支付任何款项或对您负上任何其他责任。<br>
                如我们的服务需要您下载并使用我们提供的任何软件，我们根据本条款（包括根据任何有关软件或在您的特定设备使用该软件的特定技术规定）赋予您有限、个人、非独家、不可转授、不可转让、免专利权费和可撤销的软件使用特许权，让您使用我们的服务 。您对任何我们的软件及我们的服务得使用亦受我们的《最终用户特许协议》所载条款及条件的规范，该协议已藉提述纳入本条款。请注意，特定软件适用的条款和条件可能会补充这些特许条款。<br>
                除非我们根据适用法律或规例不得予以禁止，或您已事先获得我们的书面同意，否则您不得对我们的软件进行复制、修改、反编译、倒序制造或尝试抽取源代码。如适用法律或规例授权您对我们的软件进行反编译或抽取源代码，您须先联络我们以取得所需资料。<br>
                我们可能不时更新我们的软件，该等更新可以自动或主动进行。请注意，如您没有安装软件升级或新版本，我们的服务可能会难以妥善操作，甚至完全不能操作。我们不保证一定更新我们的软件，或该更新软件可以继续支援您的设备或系统。<br>
                我们的服务对您的设备的使用<br>
                为使我们可向您提供我们的服务，我们可能需要取用或使用您用以取用该服务的相关设备（例如流动电话、平板电脑或桌面电脑）， 例如我们有可能需要使用您的设备的处理器和储存装置以完成相关的软件安装，或我们可能需要取用您的联络名单以便提供某些我们应用程式内的互动功能。您同意我们就此取用您的设备。<br>
                我们会就某一特定服务如何在该服务内或在其他方面（例如作为相关服务安装程序的一部分而通过应用程式店铺（app store））使用及取用您的设备提供进一步资料。您明白，倘若您不向我们授予该等取用或使用权，我们可能无法为您提供相关服务。<br>
                我们对任何在您的设备内的个人资料（按《私隐政策》的定义）的使用或取用均按照本条款（包括我们的《私隐政策》）的规定处理。 请注意，我们对于您就您对我们的服务或软件的使用而产生或有关的任何第三方费用（包括任何您的互联网及电讯服务供应商的费用）概不负责。<br>
                <br><span>保证和免责条款</span><br>
                我们向您保证，我们定当合理审慎地凭著合理技巧来提供我们的服务 。<br>
                除了前段提供的保证外，在适用法律和规例允许的范围内，我们所有服务和软件均「按原状」和「按提供状况」而提供，我们与我们的关联公司均没有对我们的服务、我们的软件或任何通过我们的服务提交、传送或显示的内容作出任何声明、保证或给予任何承诺，包括： (i)我们的服务或软件将不受干扰、是安全的、没有错漏或没有病毒；(ii) 我们的服务或软件将会与您的设备相容；或 (iii) 我们的服务或软件将具适销质量、适合特定用途或没有侵犯任何人的知识产权的任何声明、保证或承诺。在适用法律和规例允许的范围内，您放弃任何和所有的隐含声明、保证和承诺。<br>
                <br><span>对我们的服务负法律责任</span><br>
                在适用法律和规例允许的范围内，对于在任何情况下因本条款、或我们的服务或软件而产生的所有申诉，我们与我们的关联公司负上的总责任合共不超过以下金额： (i) 您在最后一次提出申诉之日前6个月，就使用与申诉有关的特定服务或软件所支付的金额；及 (ii) 100人民币。<br>
                在适用法律和规例允许的范围内，我们或我们的关联公司在任何情况下均不会就本条款、《最终用户特许协议》或我们的服务或软件而对以下原因产生的损害负责： (i) 任何自然灾害如水灾、地震或瘟疫； (ii) 任何社会事件如战争、动乱或政府行为； (iii) 任何电脑病毒、木马或其他因恶意程序或黑客攻击产生的损害； (iv) 我们或您的软件、系统或硬件故障或失灵，或任何通讯线路出现故障； (v) 不当或未获准使用我们的服务或软件； (vi) 您使用我们的服务或软件违反本条款或《最终用户特许协议》；或 (vii) 其他在我们无法合理控制或预见的原因。在适用法律和规例允许的范围内，我们在任何情况下也不必对任何间接、特殊、相应而产、惩戒性或惩罚性的损害赔偿或任何业务、收益、盈利、商誉、内容或数据的损失负责。<br>
                除非根据适用法规和规例可能属于被豁免、受限制或被排除的范围内，否则本条款没有条文限制或排除以下任何法律责任：<br>
                对死亡或人身伤害负上的任何法律责任；<br>
                对严重疏忽或故意失当行为负上的任何法律责任；或根据适用法律和规例属于不可豁免、受限制或被排除的范围内的任何其他法律责任。<br>
                即使本条款另有任何其他条文，本条款没有任何条文限制或排除您在您司法管辖区的任何法定权利（包括根据适用消费者保障规例的任何权利），只要该等权利不属于适用法规和规例所豁免、限制或排除的范围内。<br>
                您同意您（以及您的组织（如您代表该组织使用我们的服务或软件））赔偿我们、我们的合伙人和我们的关联公司因以下原因，包括： (i) 您使用我们的服务或软件；或 (ii) 您违反本条款（包括《最终用户特许协议》的条款），而产生的任何申诉、起诉、诉讼、要求、损害赔偿、债务、损失、费用、开支（包括诉讼费和律师费）和法律责任。<br>
                <br><span>终止条款</span><br>
                由您使用我们的服务起直至您或我们终止您进入有关服务止的期间内均适用本条款。<br>
                我们可以暂停或终止您进入您的账户或我们的任何或所有服务： (i) 如我们合理相信您已违反本条款； (ii) 如您使用我们的服务对我们或我们的服务的其他用户造成风险、可能导致我们面临第三方提出申诉的威胁，或可能影响我们的声誉； (iii) 如您有很长时间没有使用我们的服务；或 (iv) 任何其他原因。在合理可行情况下，我们会向您发出暂停或终止服务通知。<br>
                <br><span>保留您的内容并设立后备档案</span><br>
                在终止本条款后，我们只会根据本条款（包括《私隐政策》）保留并使用您的内容。如我们暂停或终止某服务，或您或我们终止您进入我们的服务，我们不保证我们可以将您的任何内容交还给您，而且我们可能会在服务终止后的任何时间未经通知而永久删除您的内容。请确保您定期为您的内容设立后备档案。<br>
                <br><span>一般规定</span><br>
                本条款是您与我们就我们的服务而订立的全部协议。您同意您不会为本条款未明确订明的任何陈述而向我们提出申诉。本条款任何条文（或任何条文部分）无效不会影响任何其他条文（或该条文其馀部分）的效力或强制执行能力。如法院裁定我们不能按本条款原订的方式强制执行本条款的任何部分，我们可以在适用法律和规例容许强制执行的范围内，採用类似条款取代该等条款，而本条款的其馀条款维持不变。凡延误强制执行本条款的任何条文，一概不会被诠释为放弃于该条文的任何权利。于本条款的任何权利和责任（就性质而言）应继续有效，包括但不限于与各方各自的法律责任有关的任何责任或各方给予的赔偿（如有），在本条款终止或届满后将继续有效。<br>
                除了您与我们外，在符合任何适用的法律和规章下，任何人均无权通过法律的施行或其他方式，未经我们同意向任何人强制执行本条款，而您亦不可未经我们事先同意转授、出让或转让本条款或于本条款的任何权利或责任。我们可以未经事先获得您的同意或未向您发出通知，随意全部或局部出让、转让或对外分包本条款或我们于本条款的权利和责任。 您确认知晓并同意，我们的合伙人或关联公司在任何情况下均不必对本条款承担任何法律责任。<br>
                <br><span>管辖法律和争议的解决</span><br>
                除了在以下范围内： (i) 任何纳入本条款的适用额外条款另行规定，或 (ii) 您的司法管辖区的适用法律和规例另行规定（例如，您可能在您的司法管辖区有法定权利向当地法院（包括小额钱债法院（或类似法院）提出申诉或对申诉作出抗辩），本条款以及本条款产生或有关的任何争议或申诉将受香港特别行政区的法律管辖。<br>
                凡因本条款产生、有关或相关的任何争议、纠纷或申诉（不论是合约、侵权或其他方面的），包括本条款的存在、效力、解释、履行、违反或终止，均将根据提交《仲裁通知》时有效的《深圳国际仲裁中心机构仲裁规则》，提交深圳国际仲裁中心最后解决。仲裁地点为深圳。仲裁庭只由一名仲裁员组成。仲裁程序所用语言为中文。<br>
                <br><span>《 服务条款 》（美国特定条款）</span><br>
                如果您是我们的服务在美利坚合众国的用户，以下条款纳入本条款之内，并在不一致的范围内凌驾本条款。<br>
                如果您是加州居民，您同意放弃《加州民法》（California Civil Code）第 1542条及任何其他司法管辖区的类似条文（如您是该其他司法管辖区的居民），《加州民法》第 1542条规定：「一般豁免不延展至债权人在豁免生效时不知晓或不怀疑存在的对其有利的索赔，若其知晓，该豁免必定会对其与债权人之间的和解产生重大影响。」<br>
                本条款每一方不可撤回地放弃就本条款或根据本文拟进行的交易所产生或有关的任何和所有在有陪审团的情况下进行审讯的权利，或在任何法律程序中参与集体诉讼的权利。<br>
                <br><span>《 服务条款 》（澳洲特定条款）</span><br>
                如果您是我们的服务在澳洲的用户，以下条款纳入本条款之内，并在不一致的范围内凌驾本条款。<br>
                在适用法律和规例允许的最大范围内，所有有关本条款或其标的事项但不包括在本条款内的明确或隐含担保、保证、声明或其他条款和条件，均排除在本条款之外。<br>
                本条款没有任何条文排除、限制或修改任何适用法律和规例隐含或施加的、而且不可合法排除、限制或修改的任何担保、保证、条款或条件、权利或补偿。<br>
                如任何适用法律和规例隐含或施加任何不可被排除的担保、条件、保证或条款（「不可排除条款」），但我们能够限制您在不可排除条款被违反时所获得的补偿，则我们在不可排除条款被违反时负上的法律责任，按我们选择的下述一项或多项补偿为限：<br>
                对货品而言，取代货品或提供类似的货品、修理货品、支付取代货品的费用、支付购买类似货品的费用或支付修理货品的费用；或对服务而言，再次提供服务或支付再次提供服务的费用。<br>
            </li>
        </ul>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    async mounted() {
        //页面跳转默认顶部
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.ServiceAgreement{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .content{
        padding: 35px 26px 117px;
        li{
            &:nth-child(1){
                font-size: 18px;
                font-weight: bold;
                color: #222222;
                margin-bottom: 20px;
            }
            &:nth-child(2){
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }
            &:nth-child(3){
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 30px;
                span{
                    font-weight: bold;
                }
            }
        }
    }
}
</style>